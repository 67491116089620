import axios from "../axios.instance";

const CancelToken = axios.CancelToken;
let source = '';

export default class BoatService {
    async getBoatList(){
        try {
            const res = await axios.get('/boat')
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }

    async addBoat(data){
        try {
            if(data != ''){
                const res = await axios.post('/boat', {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateBoat(boat_id, data) {
        try {
            if(data != '') {
                const res = await axios.put('/boat/'+boat_id, {data})
                return res.data;
            }
        } catch (error) {
            // const err = error.response
            return error.response.data
        }
    }

    async deleteBoat(boat_id){
        try {
            if(boat_id != 0) {
                const res = await axios.delete('/boat/'+boat_id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    //=================BOAT FEE=================
    async getBoatFee(id) {
        try {
            source = CancelToken.source();
            const res = await axios.get('/boatfee/'+id, {
                cancelToken: source.token
            })
            return res.data
        } catch (error) {
            return error.response.data
        }
    }
    
    cancelRequest() {
        source.cancel('Request Canceled');
    }

    async addBoatFee(data){
        try {
            if(data != ''){
                const res = await axios.post('/boatfee', {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateBoatFee(boat_id, data) {
        try {
            if(data != '') {
                const res = await axios.put('/boatfee/'+boat_id, {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteBoatFee(boat_id){
        try {
            if(boat_id != 0) {
                const res = await axios.delete('/boatfee/'+boat_id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

}