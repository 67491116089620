<template>
<div class="boat-list">
    <div class="search-bar">
        <div class="p-input-icon-left search-field">
            <i class="pi pi-search" />
            <InputText
                type="text"
                placeholder="Search by name"
                v-model="filters['global'].value"
            />
        </div>
        <Button v-if="can('create', 'boat')" label="Add" @click="openBoatFormDialog('add')" class="p-button-sm" icon="pi pi-plus" />
    </div>   
    
    <div class="content-section implementation">
        <div class="boat-datatable card">
            <DataTable
                :value="boatCommunityList"
                responsiveLayout="scroll"
                scrollHeight="280px"
                v-model:selection="selectedBoatCommunity" 
                selectionMode="single"
                v-model:filters="filters"
                :globalFilterFields="['community_name']"
                @rowSelect="onRowSelect"
                dataKey="id"
            >
                <Column field="id" header="ID" :style="{'min-width':'50px'}"></Column>
                <Column field="community_name" header="Company/Community Name" :style="{'min-width':'205px'}"></Column>
                <Column field="province" header="City/Province" :style="{'min-width':'120px'}"></Column>
                <Column field="phone" header="Phone" :style="{'min-width':'110px'}"></Column>
                <Column field="admission_fee" header="Admission fee" :style="{'min-width':'120px'}">
                    <template #body="{data}">
                            {{data.admission_fee != null ? 'USD ' + data.admission_fee + '/pax' : ''}}
                        </template>
                </Column>

                <Column v-if="can('update', 'boat') || can('delete', 'boat')" :exportable="false" :style="{'min-width':'90px', 'padding':'0.4rem 0.5rem', 'text-align':'right'}">
                    <template #body="slotProps">
                        <Button v-if="can('update', 'boat')" icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" @click="openBoatFormDialog('update', slotProps.index)"/>
                        <Button v-if="can('delete', 'boat')" icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteBoat(slotProps.data)" />
                    </template>
                </Column>
            </DataTable>
            <ConfirmDialog></ConfirmDialog>
        </div> 

        <Dialog v-model:visible="showBoatFormDialog" :style="{width: '45rem', margin: '0px 5px'}" :modal="true">
            <template #header>
                <h5 style="margin: 0px">{{dialogHeader}}</h5>
            </template>
            <BoatForm :FormType="form_type" @RefreshBoatList="getBoatData()" />
        </Dialog>
        
        <div class="tabmenu card">
            <TabView lazy class="tabview-custom-header">
                <TabPanel>
                    <template #header>
                        <i class="pi pi-wallet"></i>
                        <span>Boat fee</span>
                    </template>
                    
                    <BoatFee :BoatID="boatID" :BoatCommunity="boatCommunity" />
                </TabPanel>
            </TabView>
        </div>
    </div>
</div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { useAbility } from '@casl/vue';
import {FilterMatchMode} from 'primevue/api';
import BoatForm from '../components/BoatForm.vue';
import BoatFee from './BoatFee.vue';

import BoatService from '../service/BoatService';

export default {
    setup() {
        const { can } = useAbility()
        const store = useStore()
        const confirm = useConfirm()
        const toast = useToast()

        let boatCommunityList = ref([])
        let selectedBoatCommunity = ref()
        const filters = ref({
            'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        });
        let boatID = ref(0) //USE TO SEND BOAT COMMUNITY ID TO GET BOAT FEE LIST DATA
        let boatCommunity = ref('') //USE TO SEND BOAT COMMUNITY NAME TO GET BOAT FEE FORM

        let dialogHeader = ref('')
        let form_type = ref('')
        const showBoatFormDialog = ref(false)

        const boatService = ref(new BoatService())

        onMounted(() => {
            getBoatData()
        })

        //=================Method=================
        const onRowSelect = (event) => {
            boatID.value = event.data.id
            boatCommunity.value = event.data.community_name
            // store.dispatch('setContactPerson_RestID', event.data.id)
            // store.dispatch('setIsRestaurantContactList', true)
        }

        const getBoatData = () => {
            boatService.value.getBoatList().then((data) => {
                if(!data.errorResponse) {
                    boatCommunityList.value = data;
                } else {
                    toast.add({severity:'warn', summary: 'Error ' + data.status, detail: data.errorResponse, life: 5000});
                }
            });
        }

        const openBoatFormDialog = (formtype, boatDataIndex) => {
            showBoatFormDialog.value = true
            form_type.value = formtype
            
            if(formtype == 'add'){
                dialogHeader.value = 'Add Boat Community/Company'
            } else {
                dialogHeader.value = 'Update Boat Community/Company'
                selectedBoatCommunity.value = boatCommunityList.value[boatDataIndex]
                store.dispatch('boat_updateform', boatCommunityList.value[boatDataIndex])
            }
        }

        const confirmDeleteBoat = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete "'+ data.community_name +'"?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const boat_id = data.id
                    boatService.value.deleteBoat(boat_id).then((data) => {
                        if(!data.errorResponse){
                            boatCommunityList.value = boatCommunityList.value.filter(val => val.id !== boat_id);
                        } else {
                            toast.add({severity:'warn', summary: 'Delete Error ' + data.status, detail: data.errorResponse, life: 5000});
                        }
                    })
                }
            });
        }

        return {
            can,
            filters,
            boatCommunityList,
            selectedBoatCommunity,
            getBoatData,
            onRowSelect,
            
            dialogHeader,
            form_type,
            openBoatFormDialog,
            showBoatFormDialog,

            confirmDeleteBoat,

            boatID,
            boatCommunity,
        }
    },
    components: {
        BoatForm,
        BoatFee,
    }
}
</script>

<style lang="scss" scoped>
.boat-datatable {
    height: 300px;
}

.tabmenu {
    height: auto;
    min-height: 22.5rem;
    padding: 0px 10px;
}
</style>