import axios from '../axios.instance'

export default class CambodiaProvinceService {
    async getProvinces(){
        try {
            const res = await axios.get('/getprovince')
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }
}