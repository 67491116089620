<template>
<form @submit.prevent="FormType == 'add' ? addBoatCommunity() : updateBoatCommunity()">
    <div class="boat-form">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <label for="boat_community_name" class="p-col-fixed">Community/Company name*:</label>
                <div class="p-col">
                    <InputText id="boat_community_name" v-model="boatForm.community_name" type="text" :class="{'p-invalid':v$.community_name.$error || !isBoatCommunityNameValid}" />
                    <small v-if="v$.community_name.$error" class="p-error">{{v$.community_name.required.$message.replace('Value', 'Community/Company name')}}</small>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="city" class="p-col-fixed">City/Province*:</label>
                <div class="p-col">
                    <Dropdown v-model="selectedProvinceDropdown" :options="provinceDropdown" @change="onProvinceChange()" optionLabel="province_name" :class="{'p-invalid':v$.province.$error}">
                    </Dropdown>
                    <small v-if="v$.province.$error" class="p-error">{{v$.province.required.$message.replace('Value', 'Province')}}</small>  
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="tel" class="p-col-fixed">Phone:</label>
                <div class="p-col">
                    <InputMask v-model="boatForm.phone" mask="999 999-999?9"/>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="admission_fee" class="p-col-fixed">Admission fee (USD):</label>
                <div class="p-col">
                    <div style="display: flex">
                        <InputNumber id="admission_fee" v-model="boatForm.admission_fee" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" />
                        <label for="admission_fee" class="p-ml-2 p-mt-1" style="width: 100px; margin: 0">per pax.</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-fixed">
                <div v-if="FormType == 'add'">
                    <Button v-if="can('create', 'boat')" label="Save" icon="pi pi-save" type="submit" class="p-button-success" />
                </div>
                <div v-else>
                    <Button v-if="can('update', 'boat')" label="Update" icon="pi pi-save" type="submit" class="p-button-warning" />
                </div>
            </div>
            <div class="p-col-fixed">
                <InlineMessage v-if="messages.isMsgShow" :severity="messages.severity" class="p-ml-4">{{ messages.content }}</InlineMessage>
            </div>	
        </div>
    </div>
</form>
</template>

<script>
import { ref, reactive, onMounted, computed } from 'vue'
import { useStore } from 'vuex';
import { useAbility } from '@casl/vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import BoatService from '../service/BoatService';
import CambodiaProvinceService from '../service/CambodiaProvinceService';
export default {
    props: {
        FormType: String
    },
    emits: ['RefreshBoatList'],
    setup(props, { emit }) {
        const { can } = useAbility()
        const store = useStore()

        let boatForm = reactive({})
        let boat_info = reactive({}) //Get data from store to fill in form
        let boat_id = ref(0) //Save restaurant id to use in update form
        let isBoatCommunityNameValid = ref(true)

        let provinceDropdown = ref([])
        const selectedProvinceDropdown = ref()

        let messages =  computed(() => store.state.showMsg)

        //On Create
        const boatService = ref(new BoatService())
        const provinceService = ref(new CambodiaProvinceService())
        
        onMounted(() => {
            initBoatForm()

            if(props.FormType == 'update') {
                boat_info = store.state.boatInfo.boatUpdateForm
                
                boat_id.value = boat_info.id
                setProvinceDropdown(boat_info.province)
                Object.assign(boatForm, boat_info)
            } else {
                setProvinceDropdown()
            }
        })

        //===============Methods===============
        const initBoatForm = () =>{
            const initForm = {
                community_name: '',
                province: '',
                phone: '',
                admission_fee: null,
            }
            selectedProvinceDropdown.value = null
            Object.assign(boatForm, initForm)
        }

        const setProvinceDropdown = async (province_name) => {
            await provinceService.value.getProvinces().then(data => provinceDropdown.value = data)

            if (province_name != ''){
                const province = provinceDropdown.value.filter(item => item.province_name == province_name)
                selectedProvinceDropdown.value = province[0]
            }
        }

        const addBoatCommunity = () => {
            if(validateForm()) {
                boatService.value.addBoat(boatForm).then(data => {
                    if(!data.errorResponse) {
                        if(data.status == 400) {
                            showMessage('warn', data.message, 5000)
                            isBoatCommunityNameValid.value = false
                        } else {
                            initBoatForm()
                            emit('RefreshBoatList')
                            showMessage('success', 'Boat community/company has been added.', 3500)
                            v$.value.$reset() //Reset validations
                            isBoatCommunityNameValid.value = true
                        }
                    }
                })
            }
        }

        const updateBoatCommunity = () => {
            if(validateForm()) {
                delete boatForm.id //DELETE ID FIELD BECAUSE IT DOESN'T NECESSARY TO UPDATE
                
                const update_data = {
                    community_name: boatForm.community_name == boat_info.community_name ? '': boatForm.community_name,
                    province: boatForm.province,
                    phone: boatForm.phone,
                    admission_fee: boatForm.admission_fee,
                }
                boatService.value.updateBoat(boat_id.value, update_data).then(data => {
                    if(!data.errorResponse) {
                        if(data.status == 400){
                            showMessage('warn', data.message, 5000)
                            isBoatCommunityNameValid.value = false
                        } else {
                            showMessage('success', 'Boat community/company has been updated.', 3500)
                            emit('RefreshBoatList')
                            v$.value.$reset() //Reset validations
                            isBoatCommunityNameValid.value = true
                        }
                    }
                })
            }
        }

        const showMessage = (severity, content, life) => {
            let message = {
                isMsgShow: true, 
                severity: severity, 
                content: content
            }

            store.dispatch('showMsg', message)
            if(life) {
                setTimeout(() => {
                    store.dispatch('showMsg', { isMsgShow: false, severity: '', content: '' })
                }, life);
            }
        }

        const onProvinceChange = () => {
            boatForm.province = selectedProvinceDropdown.value.province_name
        }

        //Form Validations
        let rules = computed(() =>  {
            return {
                community_name: { required },
                province: { required },
            }
        })
        const v$ = useVuelidate(rules, boatForm)
        const validateForm = () => {
            v$.value.$validate();
            if(!v$.value.$error){
                return true
            } else {
                return false
            }
        }

        return {
            can,
            v$,
            boatForm,
            addBoatCommunity,
            updateBoatCommunity,
            isBoatCommunityNameValid,

            provinceDropdown,
            selectedProvinceDropdown,
            onProvinceChange,

            messages,
        }
    },
}
</script>

<style lang="scss" scoped>
.boat-form {
    margin-top: 5px;

    label {
        width: 145px;
        margin-right: 10px;
    }
}
</style>