<template>
<div class="sub-datatable">
    <DataTable
        :value="boatFeeList"
        responsiveLayout="scroll"
        scrollHeight="400px"
        v-model:selection="selectedBoatFee" 
        selectionMode="single"
        dataKey="id"
        rowGroupMode="subheader"
        groupRowsBy="boat_type"
        class="custom-rowgroup-table"
    >
        <Column field="boat_type" header="" :style="{'min-width':'180px'}"></Column>
        <Column field="from_pax" header="From pax" :style="{'width':'250px', 'min-width':'81px'}"></Column>
        <Column field="to_pax" header="To pax" :style="{'width':'250px', 'min-width':'65px'}"></Column>
        <Column field="fee" :header="'Fee ' + boatFeeType.toLowerCase() + ' (USD)'" :style="{'width':'190px', 'min-width':'138px'}"></Column>

        <Column :exportable="false" :style="{'min-width':'90px', 'padding':'0.4rem 0.5rem', 'text-align':'right'}">
            <template #body="slotProps">
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" @click="openBoatFeeFormDialog('update', slotProps.data)"/>
                <Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteBoatFee(slotProps.data)" />
            </template>
        </Column>

        <template #groupheader="slotProps">                    
            <span style="font-weight: 500;">{{slotProps.data.boat_type}}</span>
        </template>
    </DataTable>
</div>
<hr style="border: 0; border-top: 1px solid var(--surface-d);"/>
<Button label="Add Boat Fee" @click="openBoatFeeFormDialog('add')" class="p-button-sm p-mb-2" :disabled="BoatID == 0" />

<Dialog v-model:visible="showBoatFeeFormDialog" :style="{width: '50rem'}" :modal="true">
    <template #header>
        <h5 style="margin: 0px">{{dialogHeader}}</h5>
    </template>
    <div class="p-grid">
        <div class="p-col-fixed">
            <div class="boatfee-label">
                <label>Community/Company name:</label>
            </div>
        </div>
        <div class="p-col">
            <span style="font-weight: 600;">{{ BoatCommunity }}</span>
        </div>
    </div>

    <div class="p-grid p-fluid boat-fee-form" style="margin-top: .5rem; margin-bottom: 1rem">
        <div class="p-col-6">
            <label>Boat type: </label>
            <Dropdown v-model="selectedBoatType" @change="onBoatTypeChange()" :options="boatType" optionLabel="type" :class="{'p-invalid':v$.boat_type.$error}" />
        </div>
        <div class="p-col-6">
            <label>Fee type: </label>
            <Dropdown v-model="selectedFeeType" @change="onFeeTypeChange()" :options="feeType" optionLabel="fee_type" :class="{'p-invalid':v$.fee_type.$error}" />
        </div>
        <div class="p-col-4">
            <label>From pax: </label>
            <InputNumber id="from_pax" v-model="boatFeeForm.from_pax" :class="{'p-invalid':v$.from_pax.$error}"/>
        </div>
        <div class="p-col-4">
            <label>To pax: </label>
            <InputNumber id="to_pax" v-model="boatFeeForm.to_pax" :class="{'p-invalid':v$.to_pax.$error}" />
        </div>
        <div class="p-col-4">
            <label>Fee (USD): </label>
            <InputNumber id="fee" v-model="boatFeeForm.fee" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" :class="{'p-invalid':v$.fee.$error}" />
            <small v-if="v$.fee.$error" class="p-error">Fee must be > 0</small>
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col-fixed">
            <div v-if="form_type == 'add'">
                <Button label="Save" icon="pi pi-save" class="p-button-success" @click="addBoatFee()" />
            </div>
            <div v-else>
                <Button label="Update" icon="pi pi-save" class="p-button-warning" @click="updateBoatFee()" />
            </div>
        </div>
        <div class="p-col-fixed">
            <InlineMessage v-if="messages.isMsgShow" :severity="messages.severity" class="p-ml-4">{{ messages.content }}</InlineMessage>        </div>	
    </div>
</Dialog>
</template>

<script>
import { ref, reactive, computed, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useConfirm } from "primevue/useconfirm";
import useVuelidate from '@vuelidate/core';
import { required, minValue } from '@vuelidate/validators';

import BoatService from '../service/BoatService';

export default {
    props: {
        BoatID: Number,
        BoatCommunity: String
    },
    setup(props) {
        const confirm = useConfirm()
        const store = useStore();

        let boatFeeList = ref([])
        let selectedBoatFee = ref()
        const showBoatFeeFormDialog = ref(false)
        let boatFeeType = ref('')

        const boatService = ref(new BoatService())

        //=================Method=================
        watchEffect((onInvalidate) => {       
            if(props.BoatID != 0)   {
                getBoatFeeData()

                //Cancel previous request if boat_id changed
                onInvalidate(() => {
                    boatService.value.cancelRequest()
                })
            }
        })

        const getBoatFeeData = () => {
            boatService.value.getBoatFee(props.BoatID).then((data) => {
                if(!data.errorResponse) {
                    boatFeeList.value = data;
                    boatFeeType.value = data[0].fee_type
                }
            });
        }

        const openBoatFeeFormDialog = (formtype, data) => {
            showBoatFeeFormDialog.value = true
            form_type.value = formtype

            initBoatFeeForm()
            v$.value.$reset() //Reset validations

            if(formtype == 'add'){
                dialogHeader.value = 'Add Boat Fee'
            } else {
                dialogHeader.value = 'Update Boat Fee'
                selectedFeeType.value = {fee_type: data.fee_type}
                selectedBoatType.value = {type: data.boat_type}
                const boatfee_data = {
                    from_pax: data.from_pax,
                    to_pax: data.to_pax,
                    fee: parseFloat(data.fee)
                }
                Object.assign(boatFeeForm, boatfee_data)
                boatFeeForm.id = data.id
            }
        }

        //=================BOAT FEE FORM=================
        let boatFeeForm = reactive({})
        let form_type = ref('')
        let dialogHeader = ref('')

        const boatType = [{type: 'Small Boat'}, {type: 'Big Boat'}]
        let selectedBoatType = ref()
        const feeType = [{fee_type: 'Per pax'}, {fee_type: 'Per boat'}]
        let selectedFeeType = ref()

        let messages = computed(() => store.state.showMsg)

        const initBoatFeeForm = () =>{
            const initForm = {
                boat_type: '',
                from_pax: null,
                to_pax: null,
                fee_type: '',
                fee: null,
                boat_community_id: null,
            }

            selectedBoatType.value = null
            Object.assign(boatFeeForm, initForm)
        }

        const onBoatTypeChange = () => {
            boatFeeForm.boat_type = selectedBoatType.value.type
        }

        const onFeeTypeChange = () => {
            boatFeeForm.fee_type = selectedFeeType.value.fee_type
        }

        const addBoatFee = () => {
            if(validateBoatFeeForm()) {
                boatFeeForm.boat_community_id = props.BoatID
                boatService.value.addBoatFee(boatFeeForm).then(data => {
                    if(!data.errorResponse) {
                        initBoatFeeForm()
                        showMessage('success', 'Boat fee has been saved.', 3500)
                        getBoatFeeData()
                        v$.value.$reset() //Reset validations
                    }
                })
            }
        }

        const updateBoatFee = () => {
            boatFeeForm.boat_type = selectedBoatType.value.type
            boatFeeForm.fee_type = selectedFeeType.value.fee_type

            if(validateBoatFeeForm()) {
                delete boatFeeForm.boat_community_id
                
                boatService.value.updateBoatFee(boatFeeForm.id, boatFeeForm).then(data => {
                    if(!data.errorResponse) {
                        showMessage('success', 'Boat fee was updated successfully.', 4000)
                        getBoatFeeData()
                    } else {
                        showMessage('warn', data.errorResponse, 5000)
                    }
                })
            }
        }

        const confirmDeleteBoatFee = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete this this boat fee?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const boatfee_id = data.id
                    boatService.value.deleteBoatFee(boatfee_id).then((data) => {
                        if(!data.errorResponse){
                            boatFeeList.value = boatFeeList.value.filter(val => val.id !== boatfee_id);
                        } else {
                            showMessage('warn', data.errorResponse, 5000)
                        }
                    })
                }
            });
        }

        const showMessage = (severity, content, life) => {
            let message = {
                isMsgShow: true, 
                severity: severity, 
                content: content
            }

            store.dispatch('showMsg', message)
            if(life) {
                setTimeout(() => {
                    store.dispatch('showMsg', { isMsgShow: false, severity: '', content: '' })
                }, life);
            }
        }
        
        //Form Validations
        let rules = computed(() =>  {
            return {
                boat_type: { required },
                from_pax: { required },
                to_pax: { required },
                fee_type: { required },
                fee: { required, minValue: minValue(1) },
            }
        })
        const v$ = useVuelidate(rules, boatFeeForm)
        const validateBoatFeeForm = () => {
            v$.value.$validate();
            if(!v$.value.$error){
                return true
            } else {
                return false
            }
        }

        return {
            boatFeeList,
            selectedBoatFee,
            openBoatFeeFormDialog,
            showBoatFeeFormDialog,
            dialogHeader,
            boatFeeType,

            v$,
            form_type,
            boatFeeForm,
            boatType,
            selectedBoatType,
            onBoatTypeChange,
            feeType,
            selectedFeeType,
            onFeeTypeChange,

            addBoatFee,
            updateBoatFee,
            confirmDeleteBoatFee,
            messages,
        }
    },
}
</script>

<style lang="scss" scoped>
.sub-datatable {
    min-height: 400px;
}
.custom-row {
    margin-top: 0.2rem;
}
.boat-label {
    margin-bottom: 10px;
    width: 80px;
}
</style>